<template>
  <div>
    <v-row class="mb-4">
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-text-field
            :label="$t('user.search.label')"
            :placeholder="$t('user.search.placeholder')"
            v-model="search.search"
            solo
            clearable
            hide-details
            @click:clear="searchClear"
            @keypress.enter="searchItem"
        ></v-text-field>
      </v-col>
      <v-col
          class="d-flex ml-md-n5"
          cols="12"
          sm="6"
          md="4"
          lg="4"
      >
        <v-select
            v-model="search.search_select"
            :items="search_from"
            label="Search from"
            :append-icon="'mdi-chevron-down'"
            solo
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
        <v-btn
            style="padding: 24px 0 25px 0;"
            elevation="2"
            color="primary"
            medium
            class="ml-1"
            @click="searchItem"
        >
          <v-icon size="32">mdi-magnify</v-icon>
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-end" sm="12" md="5" lg="5">
        <v-btn
            :to="{name: 'users.create'}"
            style="padding: 24px 0 25px 0;"
            elevation="2"
            color="green"
            dark
            medium
            class="ml-auto mr-n4"
        >
          <v-icon size="32">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div :style="`overflow: auto; position: relative; display: inline-block; width: 100%; max-height: ${height}px;`">
      <table class="mx-table-full"  :id="table_id">
        <thead class="">
        <tr class="red">
          <th style="min-width: 40px; max-width: 40px;">ID</th>
          <th style="min-width: 40px; max-width: 40px;">UID</th>
          <th style="min-width: 180px; max-width: 180px; width: 180px;">{{ $t('user.position') }}</th>
          <th style="width: 160px; max-width: 160px; min-width: 160px; left: 0;">{{ $t('user.first_name')}}</th>
          <th style="width: 160px; max-width: 160px; min-width: 160px; left: 160px" >{{ $t('user.last_name') }}</th>
          <th style="min-width: 240px;">{{ $t('user.email') }}</th>
          <th style="min-width: 100px; max-width: 100px;">{{ $t('user.gender.title') }}</th>
          <th style="min-width: 100px; max-width: 100px; text-align: center">{{ $t('user.is_blocked') }}</th>
          <th style="min-width: 100px; max-width: 100px; text-align: center">{{ $t('user.is_admin') }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody class="xm-table-ld-cursor--default">
        <tr @click="getId(item.uid)" v-for="(item, index) in items" :key="index" :class="{'active': item.id === item_id}">
          <td>{{ item.id }}</td>
          <td>{{ item.uid }}</td>
          <td>
            <v-chip dark x-small color="blue lighten-1">
              {{ item.position }}
            </v-chip>
          </td>
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ $t(`user.gender.${item.gender}`) }}</td>
          <td style="text-align: center">
            <v-chip dark x-small :color="item.is_blocked === 0 ? 'teal darken-1' : 'deep-orange darken-4'">
              {{ $t(`bool.${ item.is_blocked === 0 ? 'no': 'yes' }`) }}
            </v-chip>
          </td>
          <td style="text-align: center">
            <v-chip dark x-small :color="item.is_admin === 0 ? 'grey lighten-2' : 'red darken-4'">
              {{ $t(`bool.${ item.is_admin === 0 ? 'no': 'yes' }`) }}
            </v-chip>
          </td>
          <td>
            <v-btn text elevation="0" small :to="{name: 'users.edit', params: {id: item.id}}">
              <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <v-pagination
        v-if="total_pages > 1"
        v-model="page"
        class="my-4"
        :total-visible="7"
        :length="total_pages"
    ></v-pagination>
  </div>

</template>

<script>
import moment from "moment";

export default {
  props: {
    table_id: {
      type: String,
      default: 'ts'
    }
  },
  data() {
    return {
      page: 1,
      meta: 20,
      total_pages: 0,
      isMobile: true,
      height: 0,
      items: [],
      item_id: 1,

      search: {
        search: null,
        search_select: 'last_name'
      },
      search_from: [
        {
          text: this.$t('user.search.select.first_name'),
          value: 'first_name'
        },
        {
          text: this.$t('user.search.select.last_name'),
          value: 'last_name'
        },
        {
          text: this.$t('user.search.select.email'),
          value: 'email'
        },
        // {
        //   text: this.$t('user.search.select.identification'),
        //   value: 'identification'
        // },
      ],
    }
  },
  watch: {
    page() {
      if (this.search.search === null) {
        this.getItems()
      } else {
        this.searchItem()
      }
    },
    'search.search'() {
      if (this.search.search === '') {
        this.search.search = null
        this.getItems()
      }
    }
  },
  created() {
    setTimeout(this.tableHeight, 300)
    this.getItems()
  },
  mounted() {
    this.tableHeight()
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    tableHeight() {
      this.height = window.innerHeight - (document.getElementById(this.table_id).getBoundingClientRect().top + 100)
    },
    getId(id) {
      this.item_id = id
      this.$emit('getId', id)
    },
    searchItem() {
      if (this.search.search !== null) {
        this.$store.dispatch('userSearch', this.search).then(() => {
          this.total_pages = 1
          this.items = this.$store.getters.users.data
        })
      }
    },
    getItems() {
      this.$store.dispatch('users', this.page).then(() => {
        this.generateItems()
      })
    },
    generateItems() {
      this.items = this.$store.getters.users.data
      this.meta = this.$store.getters.users.meta
      this.page = this.$store.getters.users.meta.current_page

      this.total_pages = Math.ceil(this.$store.getters.users.meta.total / 25)
    },
    searchClear () {
      this.getItems()
    },
    getBirthday(date,format='YYYY-MM-DD') {
      return moment(date, format).format('DD.MM.YYYY')
    }
  }
}
</script>
